import React from "react";
import { rooms } from "@/assets/rooms";
import { HiOutlineArrowRight } from "react-icons/hi2";
import axios from "axios";
import { useForm } from 'react-hook-form'
import { registerConversion, registerPlausibleEvent } from "@/utils/cookieUtilities";
import { usePlausible } from "next-plausible";

export default function CTAExpose() {
  const [loading, setLoading] = React.useState(false)
  const plausible = usePlausible();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();


  async function sendRegistration(formData) {
    setLoading(true)
    const response = await axios.post(`${process.env.NEXT_PUBLIC_FRONTEND_URL}/api/sendRegistration`, {
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      unitType: formData.unitType,
      message: formData.message,
    })
    setLoading('success')

    registerConversion();
    // registerPlausibleEvent('request-expose')
    plausible('request-expose')

    setTimeout(() => {
      setLoading(false)
    }, 5000)

    reset()
  }

  return (
    <div className="relative  md:py-12 bg-rizzBlue-800">
      <div className="absolute -top-48 " id="ctaExpose"></div>
      {loading === 'success' ? (
        <div className="absolute inset-0 flex items-center justify-center animate-fadeUp">
          <div className="flex flex-col gap-4 my-6 text-left max-w-xl text-white ">
            <h2 className="h2">Thanks for your request.</h2>
            <div className="">
              <p>
                One of our agents will get back to you shortly.
              </p>
            </div>
          </div>
        </div>
      ) : ''}

      <div className={`container !px-0 md:p-10 ${loading === 'success' ? 'opacity-0' : 'animate-fadeUp'}`}>
        <div className="relative shadow-xlö bg-rizzBlue-800 text-white px-4 py-10 md:px-10 overflow-hidden">
          <img src={'/icon-white.svg'} style={{ transform: 'scaleX(-1)' }} className="hidden lg:block absolute bottom-8 opacity-60 left-9 h-1/2" >

          </img>
          {/* <div className="hidden lg:block absolute bottom-0 right-0 lg:left-1/4 w-1/2 lg:w-1/4 h-1/2" style={{ backgroundImage: "url(monstera_1.svg)", backgroundRepeat: "no-repeat", transform: "rotate(-40deg)" }}></div> */}
          {/* <div className="absolute top-0 lg:right-[-5%] right-[-15%] w-1/2 lg:w-1/4 h-1/2" style={{ backgroundImage: "url(icon-white.svg)", backgroundRepeat: "no-repeat", transform: "rotate(-210deg)", backgroundPosition: "left bottom" }}></div> */}

          {/* <div className="hidden lg:block absolute bottom-0 left-0 w-1/2 lg:w-1/4 h-1/2" style={{backgroundImage: "url(monstera.svg)", backgroundRepeat: "no-repeat", transform: "rotate(20deg)"}}></div>
          <div className="hidden lg:block absolute bottom-0 right-0 lg:left-1/4 w-1/2 lg:w-1/4 h-1/2" style={{backgroundImage: "url(monstera_1.svg)", backgroundRepeat: "no-repeat", transform: "rotate(-40deg)"}}></div>
          <div className="absolute top-0 lg:right-[-5%] right-[-15%] w-1/2 lg:w-1/4 h-1/2" style={{backgroundImage: "url(palm.svg)", backgroundRepeat: "no-repeat", transform: "rotate(-210deg)", backgroundPosition: "left bottom"}}></div> */}
          <div className="flex flex-col lg:flex-row gap-8 md:gap-12">
            <div className="flex flex-col gap-4 my-6 text-left max-w-xl">
              <h2 className="h2">Request more info</h2>
              <div className="">
                <p>
                  We are happy to show you send you more info on the property. If you have any additional questions, feel free to include them in your enquiry.
                </p>
              </div>
            </div>
            <div className="flex-1 mt-7 z-[10]">
              <form onSubmit={handleSubmit((data) => sendRegistration(data))} className="lg:flex-auto">
                <div className="grid grid-cols-1 gap-x-8 gap-y-3 sm:grid-cols-2">
                  <div>
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-semibold leading-6 text-gray-100"
                    >
                      First name
                    </label>
                    <div className="mt-1">
                      <input
                        {...register('firstName', { required: true })}
                        type="text"
                        name="firstName"
                        id="firstName"
                        autoComplete="given-name"
                        className="block w-full rounded-none border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teracotta-500 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-semibold leading-6 text-gray-100"
                    >
                      Last name
                    </label>
                    <div className="mt-1">
                      <input
                        {...register('lastName', { required: true })}
                        type="text"
                        name="lastName"
                        id="lastName"
                        autoComplete="family-name"
                        className="block w-full rounded-none border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teracotta-500 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="unitType"
                      className="block text-sm font-semibold leading-6 text-gray-100"
                    >
                      Unit Type
                    </label>
                    <div className="mt-1">
                      <select
                        {...register('unitType', { required: true })}
                        id="unitType"
                        name="unitType"
                        className="block w-full rounded-none border-0 px-3.5 py-[0.7rem] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teracotta-500 sm:text-sm sm:leading-6"
                      >
                        {rooms.map((room) => (
                          <option value={room.name}>{room.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-semibold leading-6 text-gray-100"
                    >
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        {...register('email', { required: true })}
                        type="email"
                        name="email"
                        id="email"
                        className="block w-full rounded-none border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teracotta-500 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="message"
                      className="block text-sm font-semibold leading-6 text-gray-100"
                    >
                      Your Inquiry (optional)
                    </label>
                    <div className="mt-1">
                      <textarea
                        {...register('message')}
                        id="message"
                        name="message"
                        rows={4}
                        className="block w-full rounded-none border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teracotta-500 sm:text-sm sm:leading-6"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-6">
                  <button
                    type="submit"
                    className=" group flex justify-center items-center hover:scale-105 transform duration-300 w-full rounded-none bg-teracotta-500 px-3.5 py-2.5 text-center text-sm font-semibold text-gray-100 shadow-sm hover:bg-teracotta-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teracotta-500"
                  >
                    <span>
                      {loading ? 'Sending...' : 'Request Info'}
                    </span>
                    <HiOutlineArrowRight className=" ml-2 group-hover:translate-x-2 transform duration-300" />
                  </button>
                </div>
                <p className="mt-4 text-sm leading-6 text-gray-300">
                  By submitting this form, I agree to the{" "}
                  <a href="#" className="font-semibold text-teracotta-600">
                    privacy&nbsp;policy
                  </a>
                  .
                </p>
              </form>
            </div>
          </div>
        </div >
      </div >

    </div >
  );
}
