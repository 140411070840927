
import React from 'react'


export const rooms = [
    {
        "name": "Studio Unit A",
        "shortDescription": (
            <span>
                Studio A is a fully equipped accommodation that offers a total of 45 square meters of space. It has a king size bed, a TV, a separate fully equipped kitchen area, a balcony, a bathroom with a large shower and a separate toilet. Studio A is available on each floor and is fully furnished.
            </span>
        ),
        "description": [
            'Studio A is a fully equipped accommodation that offers a total of 45 square meters of space. It has a king size bed, a TV, a separate fully equipped kitchen area, a balcony, a bathroom with a large shower and a separate toilet. Studio A is available on each floor and is fully furnished.'
        ],
        "price": "117.000",
        "image": "/Studio A/3 studio.jpg",
        "floorPlan": "/plans/AUnit.png",
        "slug": "studio-unit-a",
        "bedrooms": 1,
        "bathrooms": 1,
        "areaM": 45,
        "areaF": 484,
        "available": 0,
        "livingroom": 0,
        "pictures": [
            "/Studio A/1 studio.jpg",
            "/Studio A/2 studio.jpg",
            "/Studio A/3 studio.jpg",
            "/Studio A/4 studio.jpg",
            "/Studio A/5 studio.jpg",
            "/Studio A/6 studio.jpg",
            "/Studio A/7 studio.jpg",
            "/Studio A/8 studio.jpg"
        ]
    },
    {
        "name": "Studio Unit B",
        "shortDescription": (
            <span>
                Studio B is a spacious accommodation with a size of 50 square meters and offers plenty of space to relax. It has a king-size bed, a TV and a fully equipped kitchen area, perfect for self-catering. An added bonus is the balcony where you can enjoy the fresh air.
            </span>
        ),
        "description": [
            'Studio B is a spacious accommodation with a size of 50 square meters and offers plenty of space to relax. It has a king-size bed, a TV and a fully equipped kitchen area, perfect for self-catering. An added bonus is the balcony where you can enjoy the fresh air. The bathroom has a large shower and there is also a separate toilet. Studio B is fully furnished, so it is perfect for a longer stay.'
        ],
        "price": "130.000",
        "image": 'https://instyle-investments.b-cdn.net/rooms-new/Rizz-59.jpg',

        "floorPlan": "/plans/BUnit.png",
        "slug": "studio-unit-b",
        "bedrooms": 1,
        "bathrooms": 1,
        "areaM": 50,
        "areaF": 538,
        "available": 0,
        "livingroom": 0,
        "pictures": [
            'https://instyle-investments.b-cdn.net/rooms-new/Rizz-59.jpg',
            'https://instyle-investments.b-cdn.net/rooms-new/Rizz-60.jpg',
            'https://instyle-investments.b-cdn.net/rooms-new/Rizz-61.jpg',
            'https://instyle-investments.b-cdn.net/rooms-new/Rizz-56.jpg',
            'https://instyle-investments.b-cdn.net/rooms-new/Rizz-62.jpg',
            'https://instyle-investments.b-cdn.net/rooms-new/Rizz-57.jpg',
            'https://instyle-investments.b-cdn.net/rooms-new/Rizz-58.jpg',
            'https://instyle-investments.b-cdn.net/rooms-new/Rizz-63.jpg',
            'https://instyle-investments.b-cdn.net/rooms-new/Rizz-64.jpg',
            'https://instyle-investments.b-cdn.net/rooms-new/Rizz-65.jpg',
            'https://instyle-investments.b-cdn.net/rooms-new/Rizz-66.jpg',
            'https://instyle-investments.b-cdn.net/rooms-new/Rizz-67.jpg',
        ]
    },
    {
        "name": "1 Bedroom Unit 75m²",
        "shortDescription": (
            <span>
                The suite described here is a beautiful and comfortable apartment with a total area of 75 square meters. The interior is modern and offers everything you need for a pleasant stay.

            </span>
        ),
        "description": [
            'The suite described here is a beautiful and comfortable apartment with a total area of 75 square meters. The interior is modern and offers everything you need for a pleasant stay.'
            ,
            'In the living room there is a large sofa where you can relax comfortably. There is also a TV with all the trimmings. The French balcony is a special highlight of the apartment and offers guests a great view.'
            ,
            'The kitchen is fully equipped with everything you need to cook and eat. A guest toilet is also available, which is especially handy if you have guests.'
            ,
            'The bedroom is furnished with a large king size bed, perfect for a restful sleep. A television is also available.'
            ,
            'The spacious bathroom is an absolute highlight of the apartment. A large shower invites you to relax and there are many luxurious extras that will make your stay even more pleasant.'
            ,
            'Overall, the suite offers a great ambience and luxurious furnishings. Here you can really feel at home and enjoy a great stay.'
        ],
        "image": 'https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0014-min.jpg',
        "floorPlan": "/plans/1Bedroom.png",
        "slug": "1-bedroom-unit",
        "bedrooms": 1,
        "bathrooms": 1.5,
        "livingroom": 1,
        "areaM": 75,
        "available": 3,
        "areaF": 807,
        "price": "195.000",
        "pictures": [
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0000-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0001-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0002-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0003-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0004-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0005-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0006-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0007-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0008-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0009-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0010-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0011-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0012-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0013-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0014-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0015-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0016-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0017-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0018-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0019-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0020-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0021-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0022-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-small/1-Bedroom-75-0023-min.jpg"
        ]
    },
    {
        "name": "1 Bedroom Unit 90m²",
        "shortDescription": (
            <span>
                The 90 square meter suite is a dream come true for those looking for comfort and luxury in one. Upon entering, you are greeted by a spacious living room with a large sofa, TV and everything you need to relax. A French balcony provides plenty of natural light and fresh air.

            </span>
        ),
        "description": [
            'The 90 square meter suite is a dream come true for those looking for comfort and luxury in one. Upon entering, you are greeted by a spacious living room with a large sofa, TV and everything you need to relax. A French balcony provides plenty of natural light and fresh air. In the fully equipped kitchen you can cook and bake as if you were at home. There is a guest toilet.'
            ,
            'The bedroom is equipped with a large king-size bed and another TV for a perfect evening. The spacious bathroom has a large shower and many luxurious extras to make your stay even more enjoyable. The entire apartment is stylishly furnished and offers all the amenities you could want.'
            ,
            'The suite is the perfect home away from home. This apartment is ideal for business travelers, couples or families looking for extravagant accommodations without sacrificing comfort and convenience.'
        ],
        "price": "234.000",
        "image": 'https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0017-min.jpg',
        "floorPlan": "/plans/1Bedroom_90.png",
        "slug": "1-bedroom-unit_90",
        "bedrooms": 1,
        "bathrooms": 1.5,
        "livingroom": 1,
        "areaM": 90,
        "available": 3,
        "areaF": 969,
        "pictures": [
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0000-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0001-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0002-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0003-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0004-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0005-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0006-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0007-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0008-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0009-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0010-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0011-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0012-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0013-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0014-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0015-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0016-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0017-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0018-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0019-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0020-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0021-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0022-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0023-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0024-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0025-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0026-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0027-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0028-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0029-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/one-bedroom-big/1-Bedroom-90-0030-min.jpg"
        ]
    },
    {
        "name": "2 Bedroom Unit",
        "shortDescription": (
            <span>
                The suite described here is a dream come true for those who want to live in luxury and style. With a total area of 130 square meters, it offers plenty of space and comfort for up to four people.

            </span>
        ),
        "description": [
            'The suite described here is a dream come true for those who want to live in luxury and style. With a total area of 130 square meters, it offers plenty of space and comfort for up to four people.'
            ,
            'The living room is the perfect place to relax after a long day. There is a large sofa where you can make yourself comfortable and enjoy the TV. The French balcony lets in plenty of light and air and offers a wonderful view of the surrounding area.'
            ,

            'The fully equipped kitchen is perfect for those who like to cook and be self-sufficient. Here you will find everything you need to prepare wonderful meals and enjoy them in the pleasant atmosphere of the suite. A guest bathroom is also available.'
            ,

            'The bedrooms are equipped with large king-size beds, providing ample space for a restful nights sleep. The television in the bedroom ensures that you can watch your favorite show as you fall asleep. Each bedroom has a spacious bathroom with a large shower and many luxurious extras such as toiletries and fluffy towels.'
            ,

            'All in all, this suite is a place to feel at home and is perfect for a relaxing and comfortable vacation.'
        ],
        "price": "299.000",
        "image": 'https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0017-min.jpg',
        "floorPlan": "/plans/2Bedroom.png",
        "slug": "2-bedroom-unit",
        "bedrooms": 2,
        "bathrooms": 2.5,
        "livingroom": 1,
        "areaM": 130,
        "available": 2,
        "areaF": 1400,
        "pictures": [
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0000-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0001-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0002-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0003-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0004-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0005-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0006-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0007-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0008-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0009-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0010-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0011-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0012-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0013-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0014-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0015-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0016-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0017-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0018-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0019-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0020-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0021-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0022-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0023-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0024-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0025-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0026-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0027-min.jpg",
            "https://instyle-investments.b-cdn.net/rooms-new/two_bedroom/2_Bedroom0028-min.jpg"
        ]
    }
]