import ModalContainer from "@/components/ModalContainer"
import { useState } from "react"
import { HiCheck } from "react-icons/hi2"

export default function Timeline() {
    const [showModal, setShowModal] = useState(false)
    const steps = [
        {
            id: '01', name: 'Virtual Tour', description: (
                <span>
                    Book a 30 minute virtual tour with us.
                    We will show you around the property and answer all your questions.
                </span>
            ), status: 'current'
        },
        {
            id: '02', name: 'Reservation', description: (
                <span>
                    Reserve the apartment by paying a $5.000 deposit. The amount will be deducted from the total price of the apartment.
                </span>
            ), status: 'upcoming'
        },
        {
            id: '03', name: 'Payment / Loan', description: (
                <span>
                    Pay the remaining amount of the apartment. If financing is required, we will connect you with our partner bank.
                </span>
            ), status: 'upcoming'
        },
        {
            id: '04', name: 'Sign Contract (Remote or in person)', description: (
                <span>
                    Sign the agreement remotely with our partner attorney (video chat), or visit our office in person.
                </span>
            ), status: 'upcoming'
        },
    ]

    return (
        <>
            {/* <ModalContainer showC={[showModal, setShowModal]} className='md:max-w-4xl'>
                <div className="p-4 lg:p-8">
                    <h2 className="h2">
                        Fly & Buy Program
                    </h2>
                    <h5 className="h5 mt-8">
                        Once you decide to purchase any of our apartments, we will reimburse or discount from the total purchase price up to US$3,000 of your travel costs, including flight and up to 5 nights at the Infiniti Blu Resort, for 2 persons, with all-inclusive covered.
                    </h5>

                    <p className="mt-8">

                        This also gives you a free consultation with one of our professional real estate representatives, who will make you become familiar with our real estate properties, our resort, the V.I.P services and amenities we offer.
                        We will also include a free consultation with our lawyer, for any legal concerns or questions that you may have.
                        All of your Lifestyle and real estate related questions will be answered.
                    </p>

                    <div className="text-gray-800 text-sm mt-8">
                        <h4 className="">
                            Terms & Conditions:
                        </h4>
                        <p>
                            Pre-registration is required. Airline tickets (regular flight) and accommodations may not exceed $3,000 U.S. Dollars for 2 persons. Must buy from our developments. Receipts will be required in order to process refund/discount. Accommodations types are subject to availability and in a first come first served basis. Not to be combined with any other discounts or promotions. Minimum investment $100.000 U.S. Dollars. Only valid for clients from the US and Canada, for other countries please contact us. Terms and conditions are subject to change without prior notice.
                        </p>
                    </div>
                </div>

            </ModalContainer> */}
            <div className="pb-10 pt-24">
                <div className="container">
                    <div className="max-w-3xl xl:col-span-2">
                        <h2 className=" h2 tracking-tight sm:text-6xl text-gray-700 font-trajanPro">
                            How to invest
                        </h2>
                        {/* <div className="mt-6 p leading-8 ">
                    </div> */}
                    </div>


                    <div className="mt-10" aria-label="Progress">
                        <ol role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
                            {steps.map((step, stepIdx) => (
                                <li key={step.name} className="relative md:flex md:flex-1 px-6 py-4">
                                    {step.status === 'complete' ? (
                                        <div className="group flex w-full items-center">
                                            <div className="flex items-center text-sm font-medium">
                                                <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-teracotta-500 group-hover:bg-teracotta-600">
                                                    <HiCheck className="h-6 w-6 text-white" aria-hidden="true" />
                                                </div>
                                                <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                                            </div>
                                        </div>
                                    ) : step.status === 'current' ? (
                                        <div className="text-sm font-medium" aria-current="step">
                                            <div className="flex items-center">
                                                <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-teracotta-500">
                                                    <span className="text-teracotta-700">{step.id}</span>
                                                </div>
                                                <span className="ml-4 text-base font-medium text-teracotta-700">{step.name}</span>
                                            </div>
                                            <div className="mt-4 font-normal text-gray-900 leading-relaxed">{step.description}</div>
                                        </div>
                                    ) : (
                                        <div className="group flex">
                                            <div className=" text-sm font-medium">
                                                <div className="flex items-center">
                                                    <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                                        <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                                                    </div>
                                                    <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                                                </div>
                                                <div className="mt-4 text-sm font-normal text-gray-900 leading-relaxed">{step.description}</div>
                                            </div>
                                        </div>
                                    )}

                                    {stepIdx !== steps.length - 1 ? (
                                        <>
                                            {/* Arrow separator for lg screens and up */}
                                            <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                                                <svg
                                                    className="h-full w-full text-gray-300"
                                                    viewBox="0 0 22 80"
                                                    fill="none"
                                                    preserveAspectRatio="none"
                                                >
                                                    <path
                                                        d="M0 -2L20 40L0 82"
                                                        vectorEffect="non-scaling-stroke"
                                                        stroke="currentcolor"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </>
                                    ) : null}
                                </li>
                            ))}
                        </ol>
                    </div>
                </div>
            </div >
        </>

    )
}
