import Script from "next/script";
import React from "react";

export default function CTATour() {
  return (
    <><div className="relative md:pt-12">
      <div className="absolute -top-12 " id="ctaTour"></div>
      <div className="container !px-0 md:px-10">
        <div className="relative text-gray-700 px-4 md:px-10 overflow-hidden">

          {/* <div className="hidden lg:block absolute bottom-0 right-0 lg:left-1/4 w-1/2 lg:w-1/4 h-1/2" style={{ backgroundImage: "url(monstera_1.svg)", backgroundRepeat: "no-repeat", transform: "rotate(-40deg)" }}></div> */}
          {/* <div className="absolute top-0 lg:right-[-5%] right-[-15%] w-1/2 lg:w-1/4 h-1/2" style={{ backgroundImage: "url(icon-white.svg)", backgroundRepeat: "no-repeat", transform: "rotate(-210deg)", backgroundPosition: "left bottom" }}></div> */}

          {/* <div className="hidden lg:block absolute bottom-0 left-0 w-1/2 lg:w-1/4 h-1/2" style={{backgroundImage: "url(monstera.svg)", backgroundRepeat: "no-repeat", transform: "rotate(20deg)"}}></div>
          <div className="hidden lg:block absolute bottom-0 right-0 lg:left-1/4 w-1/2 lg:w-1/4 h-1/2" style={{backgroundImage: "url(monstera_1.svg)", backgroundRepeat: "no-repeat", transform: "rotate(-40deg)"}}></div>
          <div className="absolute top-0 lg:right-[-5%] right-[-15%] w-1/2 lg:w-1/4 h-1/2" style={{backgroundImage: "url(palm.svg)", backgroundRepeat: "no-repeat", transform: "rotate(-210deg)", backgroundPosition: "left bottom"}}></div> */}
          <div className="flex flex-col gap-8 md:gap-12">
            <div className="flex flex-col gap-4 my-6 text-left max-w-xl">
              <h2 className="h2">Book a virtual tour</h2>
              <div className="">
                <p>
                  We are happy to show you around our properties via a 30min
                  virtual tour. Feel free to send us your questions in advance, so we can make sure to answer them during the tour.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div className="relative bg-rizzBlue-800 py-4 lg:py-0 lg:pb-4 mb-8">
        <div className="absolute -top-12 " id="ctaTour"></div>
        <div className="container !px-0 ">
          <div className="relative  text-white px-4  md:px-10 overflow-hidden">
            <img src={'/icon-white.svg'} style={{ transform: 'scaleX(-1)' }} className="hidden lg:block absolute bottom-8 opacity-60 left-9 h-1/2" >

            </img>
            {/* <div className="hidden lg:block absolute bottom-0 right-0 lg:left-1/4 w-1/2 lg:w-1/4 h-1/2" style={{ backgroundImage: "url(monstera_1.svg)", backgroundRepeat: "no-repeat", transform: "rotate(-40deg)" }}></div> */}
            {/* <div className="absolute top-0 lg:right-[-5%] right-[-15%] w-1/2 lg:w-1/4 h-1/2" style={{ backgroundImage: "url(icon-white.svg)", backgroundRepeat: "no-repeat", transform: "rotate(-210deg)", backgroundPosition: "left bottom" }}></div> */}

            {/* <div className="hidden lg:block absolute bottom-0 left-0 w-1/2 lg:w-1/4 h-1/2" style={{backgroundImage: "url(monstera.svg)", backgroundRepeat: "no-repeat", transform: "rotate(20deg)"}}></div>
          <div className="hidden lg:block absolute bottom-0 right-0 lg:left-1/4 w-1/2 lg:w-1/4 h-1/2" style={{backgroundImage: "url(monstera_1.svg)", backgroundRepeat: "no-repeat", transform: "rotate(-40deg)"}}></div>
          <div className="absolute top-0 lg:right-[-5%] right-[-15%] w-1/2 lg:w-1/4 h-1/2" style={{backgroundImage: "url(palm.svg)", backgroundRepeat: "no-repeat", transform: "rotate(-210deg)", backgroundPosition: "left bottom"}}></div> */}
            <div className="flex flex-col">
              <div className="flex-1 z-[10]">
                <div className="calendly-inline-widget" data-url="https://calendly.com/am--aii/virtual-tour?primary_color=d29656" style={{ height: '700px' }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
